<template>
  <div class="w">
    <div class="crumbs">
      <router-link to="/">首页</router-link>
      <span>/</span>
      政策法规
      <span>/</span>
      <span style="color: #07C160">{{route.query.titleOne}}</span>
    </div>
    <div class="list">
      <List v-if="flag" :getList="getList" path="/policy"></List>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref ,onMounted} from 'vue'
import List from '../components/List'
import { yncyPolciesList } from '../api'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'PolicyList',
  components: {
    List
  },
  watch: {
    // this.$route.path
    '$route.query': function (val) {
      console.log(val)
      this.flag = false
      setTimeout(() => {
        this.flag = true
      }, 100)
    }
  },
  setup () {
    const title = ref('')
    const flag = ref(true)
    const route = useRoute()
    const getList = (data) => {
      return yncyPolciesList({ type: route.query.type })
    }

    return { getList, flag, route, title }
  }

})
</script>

<style lang="scss" scoped>
.list{
  padding: 0 20px;
}
</style>
